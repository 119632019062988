body {
  font-family: 'Lato', sans-serif;
}
/* .dimmed.dimmable > .ui.modals.dimmer.visible {
  display: flex !important;
} */

.dimmed.dimmable > .ui.modals.dimmer.visible .ui {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}
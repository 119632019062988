.login-header{
  background: #000;
  color: #FFF;
  padding: 1em 2.5em;
  width: 100%;
  text-align: right;
}
.login-header div:hover {
  text-decoration: underline;
  cursor: pointer;
}

.payment-details{
  padding: 40px;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
  0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
}

/* ------ STRIPE WIDGET ----------- */

.stripe-widget{
  display: flex;
  justify-content: center;
}

.stripe-widget form {
  /* width: 30vw;
  min-width: 500px; */
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}


.stripe-widget #payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.stripe-widget #payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.stripe-widget button {
  background: #1F2D5F;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.stripe-widget button:hover {
  filter: contrast(115%);
}

.stripe-widget button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.stripe-widget .spinner,
.stripe-widget .spinner:before,
.stripe-widget .spinner:after {
  border-radius: 50%;
}

.stripe-widget .spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.stripe-widget .spinner:before,
.stripe-widget .spinner:after {
  position: absolute;
  content: '';
}

.stripe-widget .spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #1F2D5F;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.stripe-widget .spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #1F2D5F;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .stripe-widget form {
    width: 90vw;
    min-width: initial;
  }
}


.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}

@media (max-width: 768px){
  .footer-menu-item {
      width: 100%;
  }
  .dd-button{
    width: 100%;
  }
}
@media (min-width: 768px){
  .footer-menu-item-first {
    margin-left: 55px
  }

}
.footer-menu-item p{
  text-align: center;
  width: 100%;
}

.terms-desktop{
  margin-right: 25px;
  text-align: right;
}
@media (max-width: 768px){
  .terms-desktop {
      display: none;
  }
}
.term-mobile{
  margin-right: 0px;
  text-align: right;
}
@media (min-width: 768px){
  .terms-mobile {
      display: none;
  }
}

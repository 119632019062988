body {
  font-family: 'Libre Franklin', sans-serif;
}

.embed-container { 
  position: relative; 
  padding-bottom: 56.25%; 
  height: 0; overflow: hidden; 
  max-width: 100%; 
} 

.embed-container iframe, 
.embed-container object, 
.embed-container embed { 
  position: absolute; 
  top: 0; left: 0; 
  width: 100%; 
  height: 100%; 
}

.quote {
  padding-bottom: 63px;
  margin: 1.5rem 0;
}

.quote-content {

  background-color: rgba(255,255,255,.75);
  border-radius: 10px;
  padding: 14px 18px;
  text-align: center;

}

.quote-triangle {

  float: left;
  width: 0;
  height: 0;
  margin: 0 3px;
  border-left: 18px solid rgba(255,255,255,.75);
  border-bottom: 36px solid transparent;

}

.quote-person {

  float: right;
  margin-top: 3px;
  margin-right: 7px;
  font-size: 12px;
  line-height: 16px;
  text-align: right;

}

.text-muted {

  color: #818a91 !important;

}

.quote-content p {

  margin: 0;
  font-size: 14px;
  line-height: 20px;

}

.quote-avatar {

  float: left;
  width: 60px;
  height: 60px;
  margin: 5px 1px 0 7px;
  border-radius: 50%;

}
.upload-btn-wrapper {
  margin-bottom: 20px;
}
 
.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 60;
  height: 0px;
  opacity: 0;
}

.storefront--thumbImg {
  display: flex !important;
  align-items: flex-end;
}

.storefront--header {
  margin-left: 20px;
}

.storefront--header p {
  font-size: 17px;
  margin-right: 50px;
  line-height: 1.5;
  max-width: 500px;
}

.mng--dups .ui.card > .content:after {
  content: none;
}

.purchase--options {
  font-size: 14px;
  border: 3px solid #fff;
  border-radius: 20px;
  padding: 7px 15px;
  width: fit-content;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.purchase--options__active {
  background: #fff;
  border: 3px solid #fff;
  color: rgb(34, 25, 77);
  font-weight: bold;
}
/* Add clickable effect to cover image */
.storefront--thumbImg .clickable {
  position: relative;
}
.storefront--thumbImg .clickable:hover img {
  background-color: #fff;
  filter: brightness(60%);
}

.storefront--thumbImg .clickable .cursor-gif {
  width: 60px;
  height: 60px;
  background: url('./assets/img/clickmegif.gif');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
  position: absolute;
}

@media (max-width: 1191.98px) {
  .mng--dups > .column:not(.row) {
    width: 100% !important;
    margin: 0 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    padding: 1rem 1rem !important;
  }
}

@media (max-width: 767.98px) {
  .storefront--thumbImg {
    display: flex !important;
    align-items: center;
  }

  .storefront--header {
    margin-left: 0px;
    text-align: center;
  }

  .customer--title,
  .customer--fname {
    margin-bottom: 10px !important;
  }
  .storefront--header p {
    margin-right: 0;
    padding: 0 5%;
  }
}

/******* Placeholder ******/
div[name='publication'] div.default.text {
  font-size: 14px;
  color: #999 !important;
}

textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder,
textarea::placeholder,
input::-webkit-input-placeholder,
input:-moz-placeholder,
input::-moz-placeholder,
input:-ms-input-placeholder,
input::placeholder {
  color: #999 !important;
}

.storefront__opt {
  padding: 25px;
}

.storefront__opt p,
.storefront__opt label {
  font-size: 10px !important;
}

.storefront__opt label::before {
  width: 12px !important;
  height: 12px !important;
}


.d-none{
  display:none;
}

.storefront-alert{
  color: #471d70;
  width: 50%;
  font-weight: bold;
  margin: 0 auto;
  border-radius: 5px;
  padding: 0.5em;
  border: 2px solid #471d70;
  text-align: center;
}
